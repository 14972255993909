// File: logAndRedirect.js

export function logAndRedirect(url, fbclid) {
  // Get user agent from browser
  const userAgent = navigator.userAgent;
  
  // Create the payload object
  const payload = {
    url,
    fbclid,
    userAgent,
    // Note: IP will be captured server-side since we can't reliably get it from client
    timestamp: new Date().toISOString()
  };

  // Log the data to the backend
  fetch('/api/log-url', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  .then(response => {
    if (!response.ok) {
      console.error('Failed to log URL data');
    }
    // Redirect after logging (whether successful or not)
    window.open(url, '_self');
  })
  .catch(error => {
    console.error('Error logging URL data:', error);
    // Redirect even if logging fails
    window.open(url, '_self');
  });
}

// If you want to export multiple functions, you can do:
// export function anotherFunction() { ... }

// Alternatively, you can use default export if this is the only function you're exporting:
//export default logAndRedirect;