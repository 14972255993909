/* eslint-disable */
import api from "../config/apiConfig"
import { toast } from "react-toastify";
import { logAndRedirect } from './logAndRedirect';


export const saveLog = data => async dispatch => { 

   
    try {
        const { l1, l2, dropDate, kayakPickupVal, travelPickupVal, pickupDate, kayakDropVal, travelDropVal } = data.redirectData;
        //const windowReference = window.open(); 
        let link;
        if (l1 !== '' || l2 !== '') {
            link = await api.get(`/abbreviation/${l1},${l2}`);
        } else {
            link = {
                data: {
                    url: [ 'https://kayak.com', 'https://www.momondo.com' ]
                }
            }
        }

        let link1, link2;
        if(data.lang =="en" && data.siteContentLanguage == "en" && data.geolocation == "us"){
      
          console.log("----1111----link1 and link2-------", link1, link2);
           if(!data.frontTab  && !data.backTab) {
                link1 = data.defaultFrontTab;
                link2 = data.defaultBackTab;
                console.log("------1st----", link1, link2);
            }
            else if(data.frontTab  && !data.backTab) {
                link1 = data.frontTab;
                link2 = data.defaultBackTab;
            }
            else if(!data.frontTab  && data.backTab) {
                link1 = data.backTab;
                link2 = data.defaultFrontTab;
            }
            else if(data.frontTab  && data.backTab) {
                link1 = data.frontTab;
                link2 = data.backTab;
            }
        }
        else if (data.frontTab  && data.backTab ) {
            link1 = data.frontTab;
            link2 = data.backTab;
            console.log("----222222----link1 and link2-------", link1, link2);
        }
        else if(!data.frontTab  && !data.backTab) {
            link1 = data.defaultFrontTab;
            link2 = data.defaultBackTab;
            console.log("------2st----", link1, link2);
        }
        else if((!data.frontTab && data.backTab) || (data.frontTab == "" && data.backTab)) {
                link1 = data.defaultFrontTab;
                link2 = data.backTab;
                console.log("-----3nd----", link1, link2);
            }
        else if((data.frontTab && !data.backTab) || (data.frontTab && data.backTab == "")) {
                link1 = data.frontTab;
                link2 = data.defaultBackTab;
                console.log("------4rd----", link1, link2);
        }
       
        var url = "";
        
		const getUtmCampaign = () => new URLSearchParams(window.location.search).get('utm_campaign');
		const utm_campaign = getUtmCampaign();
		
        // Internal function to generate a random string
        const generateRandomString = (length = 30) => {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
          };

        const client_id=generateRandomString();
        //if (data.sendData.gclid === '') data.sendData = { ...data.sendData, gclid: '12345' };
        let currentDate = new Date();
        let dateString = Math.floor(currentDate.getTime()/1000);

        
        if((data.geolocation == "de" && data.autocomplete == "en") && (link1 == "https://www.kayak.de" || link2 == "https://www.hotelscombined.de")) {
            console.log("test------------");
            link1 = "https://www.kayak.de/in?cc=de&a=kan_242297&p=&lc=en&url=/cars";
            url = `${link1}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }
        else if(data.geolocation == "ch" && data.autocomplete == "fr" && link1 == "https://www.kayak.ch") {
            link1 = "https://www.kayak.ch/in?cc=ch&a=kan_242297&p=&lc=fr&url=/cars";
            url = `${link1}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }
        else if(data.geolocation == "ca" && data.autocomplete == "fr" && link1 == "https://www.ca.kayak.com") {
            console.log("------fjgjh--------");
            link1 = "https://www.ca.kayak.com/in?cc=ca&a=kan_242297&p=&lc=fr&url=/cars";
            url = `${link1}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }
        else if(data.geolocation == "my" && data.autocomplete == "my" && link1 == "https://www.kayak.com.my") {
            link1 = "https://www.kayak.com.my/in?cc=my&a=kan_242297&p=&lc=my&url=/cars";
            url = `${link1}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }
        else if(data.geolocation == "th" && data.autocomplete == "th" && link1 == "https://www.kayak.co.th") {
            link1 = "https://www.kayak.co.th/in?cc=th&a=kan_242297&p=&lc=th&url=/cars";
            url = `${link1}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }
        else if(data.geolocation == "th" && data.autocomplete == "en" && link1 == "https://www.kayak.co.th") {
            link1 = "https://www.kayak.co.th/in?cc=th&a=kan_242297&p=&lc=en&url=/cars";
            url = `${link1}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }
        else if(data.geolocation == "id" && data.autocomplete == "id" && link1 == "https://www.kayak.co.id") {
            link1 = "https://www.kayak.co.id/in?cc=id&a=kan_242297&p=&lc=id&url=/cars";
            url = `${link1}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }
        else if(data.geolocation == "hk" && data.autocomplete == "zh" && link1 == "https://www.kayak.com.hk") {
            link1 = "https://www.kayak.com.hk/in?cc=hk&a=kan_242297&p=&lc=zh&url=/cars";
            url = `${link1}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }
        else if(data.geolocation == "vn" && data.autocomplete == "en" && link1 == "https://www.vn.kayak.com") {
            link1 = "https://www.vn.kayak.com/in?cc=vn&a=kan_242297&p=&lc=en&url=/cars";
            url = `${link1}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }
        else if(data.geolocation == "sg" && data.autocomplete == "zh" && link1 == "https://www.hotelscombined.com.sg") {
            link1 = "https://www.hotelscombined.com.sg/in?cc=sg&a=kan_242297&p=&lc=zh&url=/cars";
            url = `${link1}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }
        else if(data.geolocation == "sg" && data.autocomplete == "zh" && link1 == "https://www.kayak.sg") {
            link1 = "https://www.kayak.sg/in?cc=sg&a=kan_242297&p=&lc=zh&url=/cars";
            url = `${link1}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }
        else {
            url = `${link1}/in?a=kan_242297&url=/cars/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${client_id}&enc_lid=${utm_campaign}`;
        }

        if (data.sendData.fbclid === '') data.sendData = { ...data.sendData, fbclid: 'None' };
        logAndRedirect(url,data.sendData.fbclid)
        //windowReference.location = url;


        var travelUrl = "";


        let text = data.lang;
        let result = text.trim();
        if(result == "en" && data.siteContentLanguage == "en" && data.geolocation == "us" || data.sendData.gclid == ''){
           
            travelUrl = `${link2}/in?a=kan_242297&url=/cars/${kayakPickupVal}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        } 
        else if(data.geolocation == "il" && data.autocomplete == "en" && link2 == "https://www.hotelscombined.co.il") {
            link2 = "https://www.hotelscombined.co.il/in?cc=il&a=kan_242297&p=&lc=en&url=/cars";
            travelUrl = `${link2}/${kayakPickupVal}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        }
        else if(data.geolocation == "th" && data.autocomplete == "th" && link2 == "https://www.hotelscombined.co.th") {
            link2 = "https://www.hotelscombined.co.th/in?cc=th&a=kan_242297&p=&lc=th&url=/cars";
            travelUrl = `${link2}/${kayakPickupVal}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        }
        else if(data.geolocation == "th" && data.autocomplete == "en" && link2 == "https://www.hotelscombined.co.th") {
            link2 = "https://www.hotelscombined.co.th/in?cc=th&a=kan_242297&p=&lc=en&url=/cars";
            travelUrl = `${link2}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        }
        else if(data.geolocation == "id" && data.autocomplete == "id" && link2 == "https://www.kayak.co.id") {
            link2 = "https://www.kayak.co.id/in?cc=id&a=kan_242297&p=&lc=id&url=/cars";
            travelUrl = `${link2}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        }
        else if(data.geolocation == "hk" && data.autocomplete == "zh" && link2 == "https://www.hotelscombined.hk") {
            link2 = "https://www.hotelscombined.hk/in?cc=hk&a=kan_242297&p=&lc=zh&url=/cars";
            travelUrl = `${link2}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        }
        else if(data.geolocation == "vn" && data.autocomplete == "en" && link2 == "https://www.vn.kayak.com") {
            link2 = "https://www.vn.kayak.com/in?cc=vn&a=kan_242297&p=&lc=en&url=/cars";
            travelUrl = `${link2}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        }
        else if(data.geolocation == "il" && data.autocomplete == "he" && link2 == "https://www.hotelscombined.co.il") {
            link2 = "https://www.hotelscombined.co.il/in?cc=il&a=kan_242297&p=&lc=he&url=/cars";
            travelUrl = `${link2}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        }
        else if(data.geolocation == "de" && data.autocomplete == "en" && link2 == "https://www.kayak.de") {
            link2 = "https://www.kayak.de/in?cc=de&a=kan_242297&p=&lc=en&url=/cars";
            travelUrl = `${link2}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        }
        else if(data.geolocation == "sg" && data.autocomplete == "zh" && link2 == "https://www.hotelscombined.com.sg") {
            link2 = "https://www.hotelscombined.com.sg/in?cc=sg&a=kan_242297&p=&lc=zh&url=/cars";
            travelUrl = `${link2}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        }
        else if(data.geolocation == "sg" && data.autocomplete == "zh" && link2 == "https://www.kayak.sg") {
            link2 = "https://www.kayak.sg/in?cc=sg&a=kan_242297&p=&lc=zh&url=/cars";
            travelUrl = `${link2}/${kayakPickupVal}${kayakDropVal ? '/' + kayakDropVal : ''}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        }
        
        else {
            console.log("----esle--------")
            travelUrl = `${link2}/in?a=kan_242297&url=/cars/${kayakPickupVal}/${pickupDate}/${dropDate}${data.mapView}&encoder=27_1&enc_pid=deeplinks&enc_cid=${data.sendData.gclid.substring(0, 50)}&enc_lid=${data.sendData.gclid.substring(50, data.sendData.gclid.length)},${dateString}`;
        }


        //window.open(travelUrl, '_self')
        api.post('/log', data.sendData);
    } catch (err) {
        toast.error(err.response.data.message);
    }
}
